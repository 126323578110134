import React, { lazy } from "react";
import Pages from "store/pages";
import Loadable from "components/Loadable";
import { CommunityApi } from "api/community/community";

const NoticeList = Loadable(lazy(() => import("./noticeList")));
const NoticeDetail = Loadable(lazy(() => import("./noticeDetail")));
const FaqList = Loadable(lazy(() => import("./faqList")));
const QnaList = Loadable(lazy(() => import("./qnaList")));
const QnaCreate = Loadable(lazy(() => import("./qnaCreate")));
const Contact = Loadable(lazy(() => import("./contactCreate")));
const ContactDetail = Loadable(lazy(() => import("./contactList")));
const ReviewList = Loadable(lazy(() => import("./reviewList")));
const ReviewDetail = Loadable(lazy(() => import("./reviewDetail")));
const ReviewCreate = Loadable(lazy(() => import("./reviewCreate")));
const NewsList = Loadable(lazy(() => import("./newsList")));
const NewsDetail = Loadable(lazy(() => import("./newsDetail")));
const InfoList = Loadable(lazy(() => import("./infoList")));
const InfoDetail = Loadable(lazy(() => import("./infoDetail")));

const CommunityConfig = {
    routes: [
        { path: Pages.COMMUNITY_NOTICE, element: <NoticeList /> },
        { path: Pages.COMMUNITY_NOTICE_DETAIL, element: <NoticeDetail /> },
        { path: Pages.COMMUNITY_FAQ, element: <FaqList /> },
        { path: Pages.COMMUNITY_QNA, element: <QnaList /> },
        { path: Pages.COMMUNITY_CONTACT, element: <Contact /> },
        { path: Pages.COMMUNITY_CONTACT_LIST, element: <ContactDetail /> },
        { path: Pages.COMMUNITY_QNA_CREATE, element: <QnaCreate /> },
        { path: Pages.COMMUNITY_REVIEW, element: <ReviewList /> },
        { path: Pages.COMMUNITY_REVIEW_DETAIL, element: <ReviewDetail /> },
        { path: Pages.COMMUNITY_REVIEW_CREATE, element: <ReviewCreate /> },
        { path: Pages.COMMUNITY_NEWS, element: <NewsList /> },
        { path: Pages.COMMUNITY_NEWS_DETAIL, element: <NewsDetail /> },
        { path: Pages.COMMUNITY_INFO, element: <InfoList /> },
        { path: Pages.COMMUNITY_INFO_DETAIL, element: <InfoDetail /> },
    ],
    API: CommunityApi
};

export default CommunityConfig;
